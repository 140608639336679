import React from 'react'
import { AnalisiPostazioniState } from '../localstate/analisiPostazioni'
import { useQsDataTable as useDataTable } from 'react-use-datatable/react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Field, Formik, getIn } from 'formik'
import { InputField } from '../../../components/Form/Fields'
import * as Yup from 'yup'
import OrdersSelectAutoComplete from '../../../components/OrdersSelectAutoComplete'
import Paginator from '../../../components/Paginator'
import ModalDeleteConfirm from '../../../components/Users/ModalDeleteConfirm'
import { AsyncMultiSelectField } from '../../../components/AsyncMultiSelectField'

export function ordersAutocompleteMultiApi(params) {
  const searchParams = new URLSearchParams(params)
  return fetch(
    `/api/planner/ordini-autocomplete/?${searchParams.toString()}`
  ).then((res) => res.json())
}

export default function StatisticheTempiLavorazione() {
  const [
    // Data State
    { analisiPostazioni, pagination, loading, totals },
    // Table state
    { page, ...filters },
    {
      addAnalisi,
      deleteAnalisi,
      updateAnalisi,
      // DT Actions
      onFiltersChange,
      onFilterChange,
      goToPage,
    },
  ] = useDataTable(AnalisiPostazioniState, () => ({}))

  const [modalAdd, setModalAdd] = React.useState(false)
  const toggleModalAdd = () => setModalAdd(!modalAdd)

  const [modalEdit, setModalEdit] = React.useState(false)
  const toggleModalEdit = () => setModalEdit(!modalEdit)
  const [selectedItemEdit, setSelectedItemEdit] = React.useState(null)

  const [modalDelete, setModalDelete] = React.useState(false)
  const toggleModalDelete = () => setModalDelete(!modalDelete)
  const [selectedItem, setSelectedItem] = React.useState(null)

  //   const [{ orders }] = useRunRj(OrdiniState, [BIG_PAGE_PARAMS])

  return (
    <div className="container-fluid p-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3>Statistiche tempi lavorazione</h3>
        <Button color="primary" onClick={() => toggleModalAdd()}>
          Aggiungi
        </Button>
      </div>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data</th>
            <th>Ordini</th>
            <th>Totali</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {analisiPostazioni?.map((postazione, i) => (
            <tr key={i}>
              <td>
                <Link to={`/statistiche-tempi-lavorazione/${postazione.id}`}>
                  {postazione.nome}
                </Link>
              </td>
              <td>
                {postazione.data &&
                  new Date(postazione.data).toLocaleDateString()}
              </td>
              <td width="30%">
                {postazione.ordini_data.map((ordine) => (
                  <div
                    key={ordine.id}
                    className="badge mr-2 mb-2"
                    style={{ backgroundColor: 'darkblue', color: 'white' }}
                  >
                    {ordine.numero_commessa} - {ordine.cliente_data.nome}
                  </div>
                ))}
              </td>
              <td>
                {Object.keys(postazione.totali).map((key, i) => (
                  <div key={i}>
                    <strong>{key}</strong>: {postazione.totali[key].toFixed(2)}
                  </div>
                ))}
              </td>
              <td>
                <Button
                  color="primary"
                  className="mr-2"
                  size="sm"
                  onClick={() => {
                    setSelectedItemEdit(postazione)
                    toggleModalEdit()
                  }}
                >
                  Modifica
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => {
                    setSelectedItem(postazione)
                    toggleModalDelete()
                  }}
                >
                  Elimina
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginator
        numPages={pagination.numPages}
        currentPage={page}
        goToPage={goToPage}
      />
      <Modal
        isOpen={modalAdd}
        toggle={toggleModalAdd}
        className="modal-dialog-centered"
      >
        <Formik
          initialValues={{
            nome: '',
            ordini: [],
          }}
          validationSchema={Yup.object().shape({
            nome: Yup.string().required('Nome obbligatorio'),
            ordini: Yup.array().required('Ordini obbligatori'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(false)
            addAnalisi.asPromise(values)
            toggleModalAdd()
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <>
              <ModalHeader toggle={toggleModalAdd}>
                Aggiungi analisi
              </ModalHeader>
              <ModalBody>
                <form onSubmit={handleSubmit}>
                  <Field
                    label="Nome"
                    type="text"
                    name="nome"
                    className="mb-2"
                    component={InputField}
                    required
                  />
                  <div className="form-group">
                    <AsyncMultiSelectField
                      label="Ordini"
                      className={'w-100'}
                      name="ordini"
                      value={values.ordini}
                      onChange={(ordini) => setFieldValue('ordini', ordini)}
                      loadInitialValues={(ids) =>
                        ordersAutocompleteMultiApi({
                          ordini: ids.join(','),
                        }).then((ordini) => {
                          return ordini.results.map((order) => ({
                            label:
                              order.numero_commessa +
                              ' - ' +
                              order.cliente?.nome,
                            value: order.id,
                          }))
                        })
                      }
                      loadOptions={(inputValue) => {
                        return ordersAutocompleteMultiApi({
                          search: inputValue,
                        }).then((ordini) => {
                          return ordini.results.map((order) => ({
                            label:
                              order.numero_commessa +
                              ' - ' +
                              order.cliente?.nome,
                            value: order.id,
                          }))
                        })
                      }}
                    />
                  </div>
                </form>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <Button color="secondary" onClick={toggleModalAdd}>
                  Chiudi
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                  Salva
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
      <Modal
        isOpen={modalEdit}
        toggle={toggleModalEdit}
        className="modal-dialog-centered"
      >
        <Formik
          initialValues={{
            nome: selectedItemEdit?.nome,
            ordini: selectedItemEdit?.ordini_data.map((ordine) => ordine.id),
          }}
          validationSchema={Yup.object().shape({
            nome: Yup.string().required('Nome obbligatorio'),
            ordini: Yup.array().required('Ordini obbligatori'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            console.log(values)
            setSubmitting(false)
            updateAnalisi.asPromise({
              ...values,
              id: selectedItemEdit.id,
            })
            toggleModalEdit()
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
          }) => (
            <>
              <ModalHeader toggle={toggleModalEdit}>
                Modifica analisi {selectedItemEdit?.nome}
              </ModalHeader>
              <ModalBody>
                <form onSubmit={handleSubmit}>
                  <Field
                    label="Nome"
                    type="text"
                    name="nome"
                    className="mb-2"
                    component={InputField}
                    required
                  />
                  <AsyncMultiSelectField
                    label="Ordini"
                    name="ordini"
                    value={values.ordini}
                    onChange={(ordini) => setFieldValue('ordini', ordini)}
                    loadInitialValues={(ids) =>
                      ordersAutocompleteMultiApi({
                        ordini: ids.join(','),
                      }).then((ordini) => {
                        return ordini.results.map((order) => ({
                          label:
                            order.numero_commessa + ' - ' + order.cliente?.nome,
                          value: order.id,
                        }))
                      })
                    }
                    loadOptions={(inputValue) => {
                      return ordersAutocompleteMultiApi({
                        search: inputValue,
                      }).then((ordini) => {
                        return ordini.results.map((order) => ({
                          label:
                            order.numero_commessa + ' - ' + order.cliente?.nome,
                          value: order.id,
                        }))
                      })
                    }}
                  />
                </form>
              </ModalBody>
              <ModalFooter className="d-flex justify-content-between">
                <Button color="secondary" onClick={toggleModalEdit}>
                  Chiudi
                </Button>
                <Button color="primary" onClick={handleSubmit}>
                  Salva
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
      <ModalDeleteConfirm
        isOpen={modalDelete}
        title={`l'analisi ${selectedItem?.nome}`}
        toggle={toggleModalDelete}
        onDelete={() => {
          deleteAnalisi.onSuccess(() => toggleModalDelete()).run(selectedItem)
          //   toggleModalDelete()
        }}
      />
    </div>
  )
}
