import React, { useState, useCallback, useContext } from "react"
import { useRunRj } from "react-rocketjump"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { qpList } from "magik-react-hooks/qpUtils"
import { OrdiniState, OrdersDomainState } from "./localstate"
import { AgentiState } from "../AdminPreventiviApp/localstate"
import { useQsDataTable as useDataTable } from "react-use-datatable/react"
import CircularProgress from "@material-ui/core/CircularProgress"
import FiltersOrders from "../../components/Planner/FiltersOrders"
import TableOrders from "../../components/Planner/TableOrders"
import Paginator from "../../components/Paginator"
import ModalDeleteOrder from "../../components/Planner/ModalDeleteOrder"
import ModalUpdateStato from "../../components/Planner/ModalUpdateStato"
import { ConfigPlannerContext } from "../../context"
import ModalCheckList from "../../components/Planner/ModalCheckList"
import ModalUpdateStatoInvioMail from "../../components/Planner/ModalUpdateStatoInvioMail"
import { MessaggiOrdiniEmailsState } from "./localstate/messaggi-ordini-emails"

const EMPTY_LIST = []

export default function ListOrdini() {
  const [
    // Data State
    { ordini, pagination, loading, deleting, updating, totals },
    // Table state
    { page, ...filters },
    {
      // DT Actions
      onFilterChange,
      onFiltersChange,
      onFilterChangeDebounced,
      goToPage,
      deleteOrder,
      updateOrder,
      refresh,
      writeCheckList,
    },
  ] = useDataTable(OrdiniState, () => ({
    stato: "",
    // Data (Commessa)
    from_data: "",
    to_data: "",
    // Data Consegna
    from_data_consegna: "",
    to_data_consegna: "",
    // Data Posa
    from_data_posa: "",
    to_data_posa: "",
    // Inizio Macchine
    from_inizio_macchine: "",
    to_inizio_macchine: "",
    // Fine Macchine
    from_fine_macchine: "",
    to_fine_macchine: "",
    // Inizio Banchi
    from_inizio_banchi: "",
    to_inizio_banchi: "",
    // Fine Banchi
    from_fine_banchi: "",
    to_fine_banchi: "",
    scheduled: "",
    non_schedulabile: "",
    search: "",
    numero_commessa_search: "",
    nome_cliente_search: "",
    tipo_cliente: "",
    agente: "",
    tipo_serramento: "",
    codice_sistema: "",
    ordering: "",
    riferimento_search: "",
    ordini_fornitori_mancanti: "",
    stato_invio_mail: "",

    done_checklist: {
      value: [],
      encdec: qpList(),
    },
    undone_checklist: {
      value: [],
      encdec: qpList(),
    },
  }))

  const [{ data: ordersDomain }, { run: fetchOrdersDomain }] =
    useRunRj(OrdersDomainState)
  const [{ data: agenti }] = useRunRj(AgentiState)
  const [{ messaggi }] = useRunRj(MessaggiOrdiniEmailsState)

  const [selectedOrdine, setOrdine] = useState(null)
  const toggleOrder = useCallback(() => setOrdine(null), [])

  const [selectedOrdineUpdateStato, setOrdineUpdateStato] = useState(null)
  const toggleOrderUpdateStato = useCallback(
    () => setOrdineUpdateStato(null),
    []
  )
  const isOpenUpdateStato = selectedOrdineUpdateStato !== null

  const { valoriStato } = useContext(ConfigPlannerContext)
  const statiOrdine = valoriStato.ordine || EMPTY_LIST

  const [modalCheckList, actionsModalCheckList] = useModalTrigger()
  const [modalInvioMail, actionsModalInvioMail] = useModalTrigger()

  return (
    <div className="container-fluid">
      {ordini === null && loading && (
        <div className="text-center mt-4">
          <CircularProgress color="primary" />
        </div>
      )}
      {ordini && (
        <>
          <FiltersOrders
            pagination={pagination}
            totals={totals}
            filters={filters}
            onFilterChange={onFilterChange}
            onFiltersChange={onFiltersChange}
            onFilterChangeDebounced={onFilterChangeDebounced}
          />
          <div className="mb-2" style={{
            height: 'calc(100vh - 170px)',
            overflowY: 'auto',
            overflowX: 'auto',
          }}>
          <TableOrders
            ordersDomain={ordersDomain}
            ordini={ordini}
            totals={totals}
            loading={loading}
            agenti={agenti}
            onOrderDeleteClick={setOrdine}
            onOrerStatoClick={setOrdineUpdateStato}
            onOpenCheckList={actionsModalCheckList.open}
            onOpenInvioMail={actionsModalInvioMail.open}
            filters={filters}
            onFilterChange={onFilterChange}
            onFiltersChange={onFiltersChange}
            onFilterChangeDebounced={onFilterChangeDebounced}
          />
          </div>
          <Paginator
            numPages={pagination.numPages}
            currentPage={page}
            goToPage={goToPage}
          />
          <ModalUpdateStato
            title={
              selectedOrdineUpdateStato &&
              `Modifica stato ordine ${selectedOrdineUpdateStato.numero_commessa}`
            }
            initialStato={
              selectedOrdineUpdateStato && selectedOrdineUpdateStato.stato
            }
            toggle={toggleOrderUpdateStato}
            isOpen={isOpenUpdateStato}
            saving={updating}
            stati={statiOrdine}
            onSave={(stato) => {
              updateOrder
                .onSuccess(() => {
                  toggleOrderUpdateStato()
                  // NOTE: can be improved
                  refresh()
                  fetchOrdersDomain()
                })
                .run({ ...selectedOrdineUpdateStato, stato })
            }}
          />
          <ModalUpdateStatoInvioMail
            ordine={modalInvioMail.value}
            isOpen={modalInvioMail.isOpen}
            messaggi={messaggi || []}
            toggle={actionsModalInvioMail.toggle}
            save={(ordine) => {
              return updateOrder
                .onSuccess(() => {
                  actionsModalInvioMail.close()
                  refresh()
                  fetchOrdersDomain()
                  // NOTE: can be improved
                })
                .asPromise({
                  ...modalInvioMail.value,
                  testo_email: ordine.testo_email,
                  stato_invio_mail: ordine.stato_invio_mail,
                })
            }}
            onClosed={actionsModalInvioMail.onClosed}
          />
          <ModalCheckList
            save={(checkList) =>
              writeCheckList
                .onSuccess(() => {
                  actionsModalCheckList.close()
                  refresh()
                })
                .asPromise(modalCheckList.value.id, checkList)
            }
            isOpen={modalCheckList.isOpen}
            toggle={actionsModalCheckList.toggle}
            compileCheckList={modalCheckList.value?.check_list}
            onClosed={actionsModalCheckList.onClosed}
          />
          <ModalDeleteOrder
            toggle={toggleOrder}
            isOpen={selectedOrdine !== null}
            ordine={selectedOrdine}
            deleting={deleting}
            onSetEliminato={() => {
              deleteOrder
                .onSuccess(() => {
                  toggleOrder()
                })
                .run(selectedOrdine)
            }}
          />
        </>
      )}
    </div>
  )
}
