import qs from 'query-string'
import request from 'superagent'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import { rj } from 'react-rocketjump'
import rjDebounce from 'react-rocketjump/plugins/debounce'
import rjList, {
  nextPreviousPaginationAdapter,
} from 'react-rocketjump/plugins/list'
import magikApi from 'magik-api'
import rjPlainList from 'react-rocketjump/plugins/plainList'
import { adjustMulitpleMaterialiFilters, rjListWithTotals } from './common'
import { CSRF, SETTINGS } from '../../../django'

// TODO: Unify API
const api = magikApi().trailingSlash(true).headers({
  'X-CSRFToken': CSRF,
})

export const OrdiniState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'Ordini',
    mutations: {
      writeCheckList: {
        effect: (orderId, checkListData) =>
          api
            .mapResponse((r) => ({ checkList: r.response, orderId }))
            .put(
              `/api/planner/ordini/${orderId}/write-check-list`,
              checkListData
            ),
        updater: (state, { orderId, checkList }) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((order) => {
              if (order.id !== orderId) {
                return order
              }
              return {
                ...order,
                check_list: checkList,
              }
            }),
          },
        }),
      },
      deleteOrder: rj.mutation.single({
        effect: (ordine) =>
          ajax({
            method: 'delete',
            url: `/api/planner/ordini/${ordine.id}`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
          }).pipe(map(() => ordine)),
        updater: 'deleteItem',
      }),
      updateOrder: rj.mutation.single({
        effect: (ordine) =>
          ajax({
            method: 'put',
            url: `/api/planner/ordini/${ordine.id}/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(ordine),
          }).pipe(map((r) => omit(r.response, 'righe_ordine'))), // NOTE avoid confusion...
        updater: 'updateItem',
      }),
    },
    effect: (filters = {}) =>
      ajax.getJSON(`/api/planner/ordini/?${qs.stringify(filters)}`),
    computed: {
      ordini: 'getList',
      totals: 'getTotals',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
      deleting: (s) => s.mutations.deleteOrder.pending,
      updating: (s) => s.mutations.updateOrder.pending,
    },
  }
)

// NOTE light beacause no breakdown on slots
export const SchedulazioniOrdineLightState = rj({
  name: 'SchedulazioniOrdineLight',
  effect: (id) => ajax.getJSON(`/api/planner/ordini/${id}/schedulazioni/`),
})

export const OrdersDomainState = rj({
  name: 'OrdersDomain',
  effect: () => ajax.getJSON(`/api/planner/ordini/domains/`),
})

export const DetailOrderState = rj({
  name: 'DetailOrder',
  mutations: {
    writeCheckList: {
      effect: (orderId, checkListData) =>
        api
          .mapResponse((r) => ({ checkList: r.response, orderId }))
          .put(
            `/api/planner/ordini/${orderId}/write-check-list`,
            checkListData
          ),
      updater: (state, { checkList }) => ({
        ...state,
        data: {
          ...state.data,
          check_list: checkList,
        },
      }),
    },
    updateOrder: {
      effect: (ordine) =>
        ajax({
          method: 'PUT',
          url: `/api/planner/ordini/${ordine.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(ordine),
        }).pipe(map((r) => r.response)),
      updater: 'updateData',
    },
    addAllegato: {
      takeEffect: 'exhaust',
      effect: (allegatoOrdine) => {
        const form = new FormData()
        form.append('allegato', allegatoOrdine.allegato)
        form.append('original_name', allegatoOrdine.allegato.name)
        form.append('ordine', allegatoOrdine.ordine)
        return ajax({
          method: 'POST',
          url: `/api/planner/allegati-ordine/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
          body: form,
        }).pipe(map((r) => r.response))
      },
      updater: (state, allegato) => ({
        ...state,
        data: {
          ...state.data,
          allegati: orderBy(
            state.data.allegati.concat(allegato),
            'created_at',
            'desc'
          ),
        },
      }),
    },
    removeAllegato: rj.mutation.single({
      effect: (id) =>
        ajax({
          method: 'DELETE',
          url: `/api/planner/allegati-ordine/${id}/`,
          headers: {
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => id)),
      updater: (state, id) => ({
        ...state,
        data: {
          ...state.data,
          allegati: state.data.allegati.filter((a) => a.id !== id),
        },
      }),
    }),
    patchOrder: {
      takeEffect: 'exhaust',
      effect: (ordine) =>
        ajax({
          method: 'PATCH',
          url: `/api/planner/ordini/${ordine.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(ordine),
        }).pipe(map((r) => r.response)),
      updater: 'updateData',
    },
    removeSchedulazione: rj.mutation.single({
      effect: (ordine) =>
        ajax({
          method: 'PUT',
          url: `/api/planner/ordini/${ordine.id}/remove-schedulazione/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(ordine),
        }).pipe(map((r) => r.response)),
      updater: (state) => ({
        ...state,
        data: {
          ...state.data,
          schedulazione_ordine: null,
        },
      }),
    }),
    deleteOrder: rj.mutation.single({
      effect: (id) => {
        return request
          .delete(`/api/planner/ordini/${id}/`)
          .set('X-CSRFToken', CSRF)
      },
      updater: (state) => state,
    }),
  },
  computed: {
    removingSchedule: (s) => s.mutations.removeSchedulazione.pending,
    removingAllegato: (s) => s.mutations.removeAllegato.pending,
    deleting: (s) => s.mutations.deleteOrder.pending,
    data: 'getData',
  },
  effect: (id) => ajax.getJSON(`/api/planner/ordini/${id}/`),
})

export const AddOrderState = rj({
  name: 'AddOrder',
  effect: (ordine) =>
    ajax({
      method: 'POST',
      url: '/api/planner/ordini/',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': CSRF,
      },
      body: JSON.stringify(ordine),
    }).pipe(map((r) => r.response)),
})

function addOrdineFornitoreApi(ordineFornitore) {
  let r = request.post(`/api/planner/ordini-fornitore/`)
  r.field('fornitore', ordineFornitore.fornitore)
    .field('tipologia', ordineFornitore.tipologia)
    .field('ordine', ordineFornitore.ordine || '')
    .field('note', ordineFornitore.note || '')
    .field('testo_pdf', ordineFornitore.testo_pdf || '')
    .field('testo_email', ordineFornitore.testo_email || '')
    .field('data_consegna', ordineFornitore.data_consegna || '')
    .field('data_arrivo', ordineFornitore.data_arrivo || '')
    .field('data_ordine', ordineFornitore.data_ordine || '')
    .field('numero_documento', ordineFornitore.numero_documento || '')
    .field('valore_percentuale', ordineFornitore.valore_percentuale || '')
    .field('domanda1', ordineFornitore.domanda1 || '')
    .field('domanda2', ordineFornitore.domanda2 || '')
    .field('domanda3', ordineFornitore.domanda3 || '')
    .set('X-CSRFToken', CSRF)
  if (ordineFornitore.immagine) {
    r.attach('immagine_pdf', ordineFornitore.immagine_pdf)
  }
  if (ordineFornitore.stato) {
    r = r.field('stato', ordineFornitore.stato)
  }
  // Allegati 2 upload
  const uploadAllegati = ordineFornitore.allegati_data
    .filter((a) => a.notUploaded === true && a.file)
    .map((a) => a.file)
  if (uploadAllegati) {
    uploadAllegati.forEach((file) => {
      r.attach('upload_allegati', file)
    })
  }
  return r.then(({ body }) => body)
}

// ordini fornitore on specific order
export const OrdineOrdiniFornitoreState = rj(rjPlainList(), {
  name: 'OrdineOrdiniFornitore',
  effect: (id, params) =>
    ajax.getJSON(
      `/api/planner/ordini/${id}/ordini_fornitore/?${qs.stringify(params)}`
    ),
  mutations: {
    addOrdineFornitore: {
      effect: addOrdineFornitoreApi,
      updater: 'insertItem',
    },
    addOrdineFornitoreWithoutInsert: {
      effect: addOrdineFornitoreApi,
      updater: (s) => s,
    },
    updateFornitore: {
      effect: (fornitore) => {
        let r = request.put(`/api/planner/fornitori/${fornitore.id}/`)
        r.field('nome', fornitore.nome)
          .field('tipologia', fornitore.tipologia)
          .field('testata', fornitore.testata || '')
          .field('testo_pdf', fornitore.testo_pdf || '')
          .field('email', fornitore.email || '')
          .field('is_default', fornitore.is_default)
          .field('tempo', fornitore.tempo)
          .field('codice', fornitore.codice || '')
          .set('X-CSRFToken', CSRF)
        if (fornitore.immagine && typeof fornitore.immagine !== 'string') {
          r.attach('immagine', fornitore.immagine)
        } else if (fornitore.remove_immagine) {
          r.attach('immagine', new File([], ''))
        }
        return r.then(({ body }) => body)
      },
      updater: (state) => state,
    },
    updateOrdineFornitore: {
      effect: (ordineFornitore) => {
        let r = request.put(
          `/api/planner/ordini-fornitore/${ordineFornitore.id}/`
        )
        r.field('fornitore', ordineFornitore.fornitore)
          .field('tipologia', ordineFornitore.tipologia)
          .field('ordine', ordineFornitore.ordine || '')
          .field('data_consegna', ordineFornitore.data_consegna || '')
          .field('data_arrivo', ordineFornitore.data_arrivo || '')
          .field('data_ordine', ordineFornitore.data_ordine || '')
          .field('numero_documento', ordineFornitore.numero_documento || '')
          .field('valore_percentuale', ordineFornitore.valore_percentuale || '')
          .field('note', ordineFornitore.note || '')
          .field('testo_pdf', ordineFornitore.testo_pdf || '')
          .field('testo_email', ordineFornitore.testo_email || '')
          .field('stato', ordineFornitore.stato || '')
          .field('domanda1', ordineFornitore.domanda1 || '')
          .field('domanda2', ordineFornitore.domanda2 || '')
          .field('domanda3', ordineFornitore.domanda3 || '')
          .set('X-CSRFToken', CSRF)
        // Allegati 2 keep
        const keepAllegati = ordineFornitore.allegati_data
          .filter((a) => !a.notUploaded)
          .map((a) => a.id)
        if (keepAllegati.length) {
          keepAllegati.forEach((id) => {
            r.field('keep_allegati', id)
          })
        } else {
          // Woraroud to tell server to delete all old allegati
          r.field('keep_allegati', 0)
        }
        // Allegati 2 upload
        const uploadAllegati = ordineFornitore.allegati_data
          .filter((a) => a.notUploaded === true && a.file)
          .map((a) => a.file)
        if (uploadAllegati) {
          uploadAllegati.forEach((file) => {
            r.attach('upload_allegati', file)
          })
        }
        if (
          ordineFornitore.immagine_pdf &&
          typeof ordineFornitore.immagine_pdf !== 'string'
        ) {
          r.attach('immagine_pdf', ordineFornitore.immagine_pdf)
        } else if (ordineFornitore.remove_immagine_pdf) {
          r.attach('immagine_pdf', new File([], ''))
        }
        return r.then(({ body }) => body)
      },
      updater: 'updateItem',
    },
    deleteOrdineFornitore: rj.mutation.single({
      effect: (ordineFornitore) => {
        const r = request
          .delete(`/api/planner/ordini-fornitore/${ordineFornitore.id}/`)
          .set('X-CSRFToken', CSRF)
        return r.then(({ body }) => ordineFornitore)
      },
      updater: 'deleteItem',
    }),
    addRighe: rj.mutation.single({
      effect: (id, righe) => {
        const r = request
          .put(`/api/planner/ordini-fornitore/${id}/add_righe/`)
          .send({
            righe,
          })
          .set('X-CSRFToken', CSRF)
        return r.then(({ body }) => body)
      },
      updater: (s) => s,
    }),
    sendMail: rj.mutation.single({
      effect: (id) => {
        const r = request
          .put(`/api/planner/ordini-fornitore/${id}/mail/`)
          .set('X-CSRFToken', CSRF)
        return r.then(({ body }) => body)
      },
      updater: (s) => s,
    }),
  },
  computed: {
    deleting: (s) => s.mutations.deleteOrdineFornitore.pending,
    sending: (s) => s.mutations.sendMail.pending,
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
})

export const OrdineOrdiniFornitoreMancantiState = rj(rjPlainList(), {
  name: 'OrdineOrdiniFornitoreMancanti',
  effect: (id) =>
    ajax.getJSON(`/api/planner/ordini/${id}/ordini_fornitore_mancanti/`),
})

export const OrdineRigheOrdineFornitoreState = rj(rjPlainList(), {
  name: 'OrdineRigheOrdineFornitoreState',
  effect: (id, params) =>
    ajax.getJSON(
      `/api/planner/ordini/${id}/righe_ordine_fornitore/?${qs.stringify(
        params
      )}`
    ),
  mutations: {
    addRigaOrdineFornitore: {
      effect: (riga) =>
        ajax({
          method: 'POST',
          url: `/api/planner/righe-ordine-fornitore/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(riga),
        }).pipe(map((r) => r.response)),
      updater: (s) => s,
    },
    updateRigaOrdineFornitore: {
      effect: (riga) =>
        ajax({
          method: 'put',
          url: `/api/planner/righe-ordine-fornitore/${riga.id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(riga),
        }).pipe(map((r) => r.response)),
      updater: (s) => s,
    },
    deleteMultiRigheOrdineFornitore: rj.mutation.single({
      effect: (idsRighe) =>
        ajax({
          method: 'DELETE',
          url: `/api/planner/righe-ordine-fornitore/multi_delete/?${`ids=${idsRighe.join(
            '&ids='
          )}`}`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
        }).pipe(
          map(() => ({
            idsRighe,
          }))
        ),
      updater: 'deleteItem',
    }),
    resetOrdineFornitore: rj.mutation.single({
      effect: (righe) => {
        const r = request
          .put(`/api/planner/righe-ordine-fornitore/reset_ordine_fornitore/`)
          .send({
            righe,
          })
          .set('X-CSRFToken', CSRF)
        return r.then(({ body }) => body)
      },
      updater: (s) => s,
    }),
    mergeRighe: rj.mutation.single({
      effect: (righe) => {
        const r = request
          .put(`/api/planner/righe-ordine-fornitore/merge/`)
          .send({
            righe,
          })
          .set('X-CSRFToken', CSRF)
        return r.then(({ body }) => body)
      },
      updater: (s) => s,
    }),
    generateOrders: rj.mutation.single({
      effect: (ids) =>
        ajax({
          method: 'put',
          url: `/api/planner/righe-ordine-fornitore/generate_orders/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: { ids: ids },
        }).pipe(map((r) => r.response)),
      updater: (s) => s,
    }),
    splittingRiga: rj.mutation.single({
      effect: (id, quantities) =>
        ajax({
          method: 'put',
          url: `/api/planner/righe-ordine-fornitore/${id}/split/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(quantities),
        }).pipe(map((r) => r.response)),
      updater: (s) => s,
    }),
    duplicateRiga: rj.mutation.single({
      effect: (id) => {
        const r = request
          .post(`/api/planner/righe-ordine-fornitore/${id}/duplicate/`)
          .set('X-CSRFToken', CSRF)
        return r.then(({ body }) => body)
      },
      updater: (s) => s,
    }),
  },
  computed: {
    generating: (s) => s.mutations.generateOrders.pending,
    deletingMulti: (s) => s.mutations.deleteMultiRigheOrdineFornitore.pending,
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
})

export const OrdineStatiMaterialeState = rj(rjPlainList(), {
  name: 'OrdineStatiMaterialiState',
  effect: (id) => ajax.getJSON(`/api/planner/ordini/${id}/stati_materiale/`),
  mutations: {
    setStato: rj.mutation.single({
      effect: (orderId, materiale, stato) => {
        return ajax({
          method: 'PUT',
          url: `/api/planner/ordini/${orderId}/set_stato_materiale/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify({
            materiale,
            stato,
          }),
        }).pipe(map(() => ({ materiale, stato })))
      },
      updater: (state, { materiale, stato }) => ({
        ...state,
        data: {
          ...state.data,
          [materiale]: stato,
        },
      }),
    }),
  },
  computed: {
    saving: (s) => s.mutations.setStato.pending,
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
})

export const FasiOrdineState = rj({
  name: 'FasiOrdine',
  mutations: {
    moveFase: {
      effect: (orderId, faseMovePayload) => {
        return ajax({
          method: 'PUT',
          url: `/api/planner/ordini/${orderId}/move-fase/?light=1`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(faseMovePayload),
        }).pipe(map((r) => r.response))
      },
      updater: (state, schedulazione) => ({
        ...state,
        data: state.data.map((fase) => {
          if (fase.nome_fase === schedulazione.nome_fase) {
            return {
              ...fase,
              schedulazione,
            }
          }
          return fase
        }),
      }),
    },
    setStato: rj.mutation.single({
      effect: (orderId, faseRow, nuovoStato) => {
        // Update or Create
        return ajax({
          method: 'PUT',
          url: `/api/planner/stati-fasi-ordine/set_stato/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify({
            ordine: orderId,
            nome_fase: faseRow.nome_fase,
            stato: nuovoStato,
          }),
        }).pipe(map((r) => r.response))
      },
      updater: (state, dataStato) => ({
        ...state,
        data: state.data.map((faseRow) => {
          if (faseRow.nome_fase === dataStato.nome_fase) {
            return {
              ...faseRow,
              stato: dataStato,
            }
          }
          return faseRow
        }),
      }),
    }),
  },
  computed: {
    data: 'getData',
    savingStato: (s) => s.mutations.setStato.pending,
  },
  effect: (id) => ajax.getJSON(`/api/planner/ordini/${id}/fasi/`),
})

export const OrdiniFornitoreState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'OrdiniFornitoreState',
    mutations: {
      updateStato: rj.mutation.single({
        effect: (ordineFornitore, newStato) =>
          request
            .put(`/api/planner/ordini-fornitore/${ordineFornitore.id}/`)
            .field('fornitore', ordineFornitore.fornitore)
            .field('tipologia', ordineFornitore.tipologia)
            //.field("ordine", ordineFornitore.ordine ? ordineFornitore.ordine.id :  "")
            .field('note', ordineFornitore.note || '')
            .field('stato', newStato)
            .set('X-CSRFToken', CSRF)
            .then(({ body }) => body),
        updater: (state, updatedOrder) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((orderRow) => {
              if (orderRow.id === updatedOrder.id) {
                return {
                  ...orderRow,
                  stato: updatedOrder.stato,
                }
              } else {
                return orderRow
              }
            }),
          },
        }),
      }),
      updateOrdineFornitore: {
        effect: (ordineFornitore) => {
          let r = request.put(
            `/api/planner/ordini-fornitore/${ordineFornitore.id}/`
          )
          r.field('fornitore', ordineFornitore.fornitore)
            .field('tipologia', ordineFornitore.tipologia)
            .field('data_consegna', ordineFornitore.data_consegna || '')
            .field('data_arrivo', ordineFornitore.data_arrivo || '')
            .field('numero_documento', ordineFornitore.numero_documento || '')
            .field(
              'valore_percentuale',
              ordineFornitore.valore_percentuale || ''
            )
            .field('data_ordine', ordineFornitore.data_ordine || '')
            .field('note', ordineFornitore.note || '')
            .field('testo_pdf', ordineFornitore.testo_pdf || '')
            .field('testo_email', ordineFornitore.testo_email || '')
            .field('stato', ordineFornitore.stato || '')
            .field('domanda1', ordineFornitore.domanda1 || '')
            .field('domanda2', ordineFornitore.domanda2 || '')
            .field('domanda3', ordineFornitore.domanda3 || '')
            .set('X-CSRFToken', CSRF)
          // Allegati 2 keep
          const keepAllegati = ordineFornitore.allegati_data
            .filter((a) => !a.notUploaded)
            .map((a) => a.id)
          if (keepAllegati.length) {
            keepAllegati.forEach((id) => {
              r.field('keep_allegati', id)
            })
          } else {
            // Woraroud to tell server to delete all old allegati
            r.field('keep_allegati', 0)
          }
          // Allegati 2 upload
          const uploadAllegati = ordineFornitore.allegati_data
            .filter((a) => a.notUploaded === true && a.file)
            .map((a) => a.file)
          if (uploadAllegati) {
            uploadAllegati.forEach((file) => {
              r.attach('upload_allegati', file)
            })
          }
          if (
            ordineFornitore.immagine_pdf &&
            typeof ordineFornitore.immagine_pdf !== 'string'
          ) {
            r.attach('immagine_pdf', ordineFornitore.immagine_pdf)
          } else if (ordineFornitore.remove_immagine_pdf) {
            r.attach('immagine_pdf', new File([], ''))
          }
          return r.then(({ body }) => body)
        },
        updater: 'updateItem',
      },
      addOrdineFornitoreWithoutInsert: {
        effect: addOrdineFornitoreApi,
        updater: (s) => s,
      },
    },
    computed: {
      updatingStato: (s) => s.mutations.updateStato.pending,
      list: 'getList',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
    effect: (params = {}) =>
      ajax.getJSON(
        `/api/planner/ordini-fornitore/?${qs.stringify(
          adjustMulitpleMaterialiFilters(params)
        )}`
      ),
  }
)

function removeControlloEffettuatoFromAttivita(attivitaList, id) {
  return attivitaList.map((attivita) => {
    let changed = false
    const nextControlli = attivita.controlli.map((controllo) => {
      if (controllo.controllo_effettuato.id === id) {
        changed = true
        return {
          ...controllo,
          controllo_effettuato: {
            ...controllo.controllo_effettuato,
            // TODO: This is bad ...
            id: null,
            note: null,
            conforme: false,
            data: null,
            operatore: null,
            operatore_data: null,
            ordine: null,
          },
        }
      }
      return controllo
    })
    if (changed) {
      return {
        ...attivita,
        controlli: nextControlli,
      }
    }
    return attivita
  })
}

export const ControlliOrdineState = rj(rjPlainList(), {
  name: 'ControlliOrdineState',
  mutations: {
    writeControlli: {
      effect: (id, controlli) => {
        return ajax({
          method: 'PUT',
          url: `/api/planner/ordini/${id}/write-controlli/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
          body: JSON.stringify(controlli),
        }).pipe(map((r) => r.response))
      },
      updater: 'updateData',
    },
    deleteControllo: rj.mutation.single({
      effect: (id) =>
        ajax({
          method: 'DELETE',
          url: `/api/planner/controlli-ordine/${id}/`,
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': CSRF,
          },
        }).pipe(map(() => id)),
      updater: (state, id) => ({
        ...state,
        data: removeControlloEffettuatoFromAttivita(state.data, id),
      }),
    }),
  },
  effect: (id) => api.get(`/api/planner/ordini/${id}/controlli/`),
})

export const AvanzamentoOrdineState = rj({
  name: 'AvanzamentoOrdineState',
  effect: (id) =>
    ajax.getJSON(`/api/planner/avanzamento-ordine?ordine=${id}&dettaglio=1`),
})

export const AvanzamentoOrdinePerCommessaState = rj(rjPlainList(), {
  name: 'AvanzamentoOrdinePerCommessaState',
  computed: {
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
  effect: (params) =>
    ajax.getJSON(
      `/api/planner/avanzamento-ordine/avanzamento-per-ordine/?${qs.stringify(
        params
      )}`
    ),
})

export const WarningMissingOrdiniFornitori = rj(rjPlainList(), {
  name: 'WarningMissingOrdiniFornitori',
  computed: {
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
  effect: (params) =>
    ajax.getJSON(
      `/api/planner/ordini-fornitore/warnings-missing-ordini-fornitori/?${qs.stringify(
        params
      )}`
    ),
})

export const WarningsOrdiniFornitori = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  {
    name: 'WarningsOrdiniFornitori',
    computed: {
      list: 'getList',
      loading: 'isLoading',
      pagination: 'getPagination',
      error: 'getError',
    },
    mutations: {
      updateStatoAndData: rj.mutation.single({
        effect: (id, values) =>
          ajax({
            method: 'PUT',
            url: `/api/planner/ordini-fornitore/${id}/update-stato-and-data-arrivo/`,
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': CSRF,
            },
            body: JSON.stringify(values),
          }).pipe(map((r) => r.response)),
        updater: (state, updatedOrder) => ({
          ...state,
          data: {
            ...state.data,
            list: state.data.list.map((orderRow) => {
              if (orderRow.id === updatedOrder.id) {
                return {
                  ...orderRow,
                  stato: updatedOrder.stato,
                }
              }
              return orderRow
            }),
          },
        }),
      }),
    },
    effect: (params) =>
      ajax.getJSON(
        `/api/planner/ordini-fornitore/warnings-ordine-fornitore-without-avanzamento/?${qs.stringify(
          params
        )}`
      ),
  }
)

export const AvanzamentoPerGiornoState = rj(rjPlainList(), {
  name: 'AvanzamentoPerGiornoState',
  computed: {
    list: 'getList',
    loading: 'isLoading',
    error: 'getError',
  },
  effect: (params) =>
    ajax.getJSON(
      `/api/planner/avanzamento-ordine/avanzamento-per-giorno/?${qs.stringify(
        params
      )}`
    ),
})

export const AvanzamentiOrdiniState = rj(
  rjDebounce(),
  rjList({
    pageSize: SETTINGS.PAGE_SIZE,
    pagination: nextPreviousPaginationAdapter,
  }),
  rjListWithTotals(),
  {
    name: 'AvanzamentiOrdiniState',
    effect: (params = {}) =>
      ajax.getJSON(`/api/planner/avanzamento-ordine/?${qs.stringify(params)}`),
    computed: {
      avanzamenti: 'getList',
      totals: 'getTotals',
      pagination: 'getPagination',
      loading: 'isLoading',
      error: 'getError',
    },
  }
)

// download xlsx file avanzamenti
export const DownloadAvanzamentiState = rj({
  name: 'DownloadAvanzamentiState',
  effect: (params) =>
    ajax({
      url: `/api/planner/avanzamento-ordine/download-avanzamenti/?${qs.stringify(
        params
      )}`,
      responseType: 'blob',
    }).pipe(map((r) => r.response)),
})
