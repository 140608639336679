import React from 'react'
import { getIn } from 'formik'
import classNames from 'classnames'
import omit from 'lodash/omit'
import find from 'lodash/find'
import { map } from 'rxjs/operators'
import { ajax } from 'rxjs/ajax'
import moment from 'moment'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AsyncPaginate } from 'react-select-async-paginate'
import it from 'date-fns/locale/it'
import {
  Input,
  FormGroup,
  FormFeedback,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import './fields.css'
import { useMemo } from 'react'

function makeOptions(list) {
  return list
    ? list.map((item) => ({
        ...item,
        label: item.numero_commessa + ' - ' + item.cliente_data.nome,
        value: item.id,
      }))
    : []
}

function getAutocompleteAPI(url, q = '', page = 1) {
  return ajax
    .getJSON(`${url}?q=${q}&page=${page}`)
    .pipe(
      map((data) => ({
        ...data,
        page,
      }))
    )
    .toPromise()
}

export const FieldRadio = ({ field, form, onClick, ...props }) => {
  const { value: checkboxValue, ...passProps } = props
  // console.log('Field', checkboxValue, field.value)
  return (
    <input
      type={'radio'}
      onClick={onClick}
      {...field}
      {...passProps}
      value={checkboxValue}
      checked={checkboxValue === field.value}
    />
  )
}

export const DateField = ({ label, field, form, style, ...props }) => {
  registerLocale('it', it)
  const m = moment(field.value, 'YYYY-MM-DD')
  const selected = m.isValid() ? m.toDate() : null

  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)

  return (
    <FormGroup className="datepicker-full-width">
      {label && <Label className="text-right">{label}</Label>}
      <div style={style}>
        <DatePicker
          autoComplete="off"
          className={classNames('form-control', {
            'is-invalid': touch && !!error,
          })}
          showYearDropdown
          locale={'it'}
          showMonthDropdown
          scrollableYearDropdown
          dropdownMode="select"
          yearDropdownItemNumber={80}
          name={field.name}
          selected={selected}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
          dateFormat="dd-MM-yyyy"
          // dateFormat='dd/MM/yyyy'
          onChange={(date, e) => {
            if (!date || date.toDateString() === 'Invalid Date') {
              form.setFieldValue(field.name, '')
            } else {
              form.setFieldValue(field.name, moment(date).format('YYYY-MM-DD'))
            }
          }}
          {...props}
        />
        {touch && error && (
          <FormFeedback className="d-block">{error}</FormFeedback>
        )}
      </div>
    </FormGroup>
  )
}

export const HourField = ({
  label,
  field,
  form,
  popperPlacement,
  minTime = '00:00',
  style,
  ...props
}) => {
  registerLocale('it', it)
  const m = moment(field.value, 'HH:mm')
  const selected = m.isValid() ? m.toDate() : null

  const minTimeMoment = moment(minTime, 'HH:mm')

  return (
    <FormGroup className="datepicker-full-width">
      {label && <Label className="text-right">{label}</Label>}
      <div style={style}>
        <DatePicker
          autoComplete="off"
          className={classNames('form-control', {
            'is-invalid': form.touched[field.name] && !!form.errors[field.name],
          })}
          locale={'it'}
          name={field.name}
          selected={selected}
          popperPlacement={popperPlacement}
          onBlur={field.onBlur}
          onFocus={field.onFocus}
          showTimeSelect
          showTimeSelectOnly
          // avoid z-index issue in modal
          portalId='root-portal'
          minTime={minTimeMoment.toDate()}
          maxTime={moment('23:59', 'HH:mm').toDate()}
          timeIntervals={15}
          timeCaption="Time"
          dateFormat="H:mm"
          // dateFormat='dd/MM/yyyy'
          onChange={(date, e) => {
            if (!date || date.toDateString() === 'Invalid Date') {
              form.setFieldValue(field.name, '')
            } else {
              form.setFieldValue(field.name, moment(date).format('HH:mm'))
            }
          }}
          {...props}
        />
        {form.touched[field.name] && form.errors[field.name] && (
          <FormFeedback className="d-block">
            {form.errors[field.name]}
          </FormFeedback>
        )}
      </div>
    </FormGroup>
  )
}

export const InputField = ({
  field,
  form: { touched, errors },
  label,
  labelClassName,
  addonText,
  rowLayout = false,
  relaxed = false,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)
  const { value: formValue, ...passField } = field
  let value
  if (relaxed && (formValue === undefined || formValue === null)) {
    value = ''
  } else {
    value = formValue
  }

  let inputElement = (
    <Input value={value} {...passField} {...props} invalid={touch && !!error} />
  )

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label
          sm={rowLayout ? 4 : undefined}
          className={classNames('text-left', labelClassName)}
        >
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const FileField = ({
  field,
  form: { touched, errors, setFieldValue },
  label,
  addonText,
  rowLayout = false,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  // const value = field.value ? field.value.name : ''

  let inputElement = (
    <Input
      {...field}
      {...props}
      value={undefined}
      onChange={(e) => setFieldValue(field.name, e.target.files[0])}
      invalid={touch && !!error}
    />
  )

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const ReadOnlyInput = ({
  label,
  value,
  addonText,
  feedbackText,
  rowLayout = false,
  ...props
}) => {
  const inputValue = value === null || value === undefined ? '' : value

  let inputElement = (
    <Input {...props} value={inputValue} disabled invalid={false} />
  )

  let feedbackElement = feedbackText && (
    <FormFeedback className="d-block">{feedbackText}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout} className="field-qr-form-readonly">
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const InputField2 = ({
  field,
  form: { touched, errors },
  label,
  addonText,
  rowLayout = false,
  ...props
}) => {
  const touch = getIn(touched, field.name)
  const error = getIn(errors, field.name)

  let inputElement = <Input {...field} {...props} invalid={touch && !!error} />

  let feedbackElement = touch && error && (
    <FormFeedback className="d-block">{error}</FormFeedback>
  )

  if (addonText) {
    inputElement = (
      <InputGroup>
        {inputElement}
        <InputGroupAddon addonType="append">
          <InputGroupText className="bg-transparent">
            {addonText}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    )
  }

  return (
    <FormGroup row={rowLayout}>
      {label && (
        <Label sm={rowLayout ? 4 : undefined} className="text-left">
          {label}
        </Label>
      )}
      {rowLayout && (
        <Col sm={8}>
          {inputElement}
          {feedbackElement}
        </Col>
      )}
      {!rowLayout && (
        <React.Fragment>
          {inputElement}
          {feedbackElement}
        </React.Fragment>
      )}
    </FormGroup>
  )
}

export const CheckboxField = ({
  field,
  label,
  form,
  checked,
  className,
  onChange,
  ...props
}) => {
  let isChecked
  if (typeof checked === 'boolean') {
    isChecked = checked
  } else {
    isChecked = field.value
  }

  return (
    <FormGroup check className={className}>
      <Label check>
        <Input
          {...props}
          type="checkbox"
          {...omit(field, 'value')}
          onChange={e => {
            onChange && onChange(e)
            field.onChange(e)
          }}
          checked={isChecked}
        />{' '}
        {label}
      </Label>
    </FormGroup>
  )
}

export const CreatableSelectField = ({
  field,
  form,
  label,
  options,
  placeholder,
}) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const value = field.value

  const optionValue = useMemo(() => {
    if (!value) {
      return ''
    }
    const foundOption = find(options, { value })
    if (!foundOption) {
      return {
        value,
        label: value,
        __isNew__: true,
      }
    }
    return foundOption
  }, [value, options])

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <CreatableSelect
        placeholder={placeholder}
        formatCreateLabel={(label) => `Nuova opzione "${label}"`}
        classNamePrefix={touch && error ? 'invalid-create-select' : ''}
        isClearable
        value={optionValue}
        onChange={(option) => {
          if (option) {
            form.setFieldValue(field.name, option.value)
          } else {
            form.setFieldValue(field.name, '')
          }
        }}
        options={options}
      />
      {touch && error && (
        <span className="text-danger">
          <small>{error}</small>
        </span>
      )}
    </FormGroup>
  )
}

export const MultiSelectField = ({
  field,
  form,
  label,
  options,
  placeholder,
  className,
}) => {
  const touch = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const value = field.value

  const optionValue = useMemo(() => {
    return options.filter((o) => value.indexOf(o.value) !== -1)
  }, [value, options])

  return (
    <FormGroup>
      {label && <Label>{label}</Label>}
      <Select
        placeholder={placeholder}
        isMulti={true}
        className={className}
        classNamePrefix={touch && error ? 'invalid-create-select' : ''}
        isClearable
        value={optionValue}
        onChange={(options) => {
          if (!options) {
            form.setFieldValue(field.name, [])
          } else {
            form.setFieldValue(
              field.name,
              options.map((o) => o.value)
            )
          }
        }}
        options={options}
      />
      {touch && error && (
        <span className="text-danger">
          <small>{error}</small>
        </span>
      )}
    </FormGroup>
  )
}

export const AutoCompleteField = ({
  label,
  field,
  form,
  setFieldValue,
  setFieldTouched,
  required,
  autocompleteUrl,
  placeholder = '',
  style,
  disabled,
  cacheUniqs,
  className,
  makeOptionsCall,
  ...props
}) => {
  const isTouched = getIn(form.touched, field.name)
  const error = getIn(form.errors, field.name)
  const showError = isTouched && error
  const requiredStar = required ? (
    <strong
      style={{
        color: 'red',
        paddingRight: 5,
      }}
    >
      *
    </strong>
  ) : undefined

  const customStyles = {
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: '#495057',
        fontSize: '1rem',
        fontWeight: 400,
      }
    },
  }

  const myBlur = field.onBlur(field.name)

  return (
    <FormGroup>
      {label && (
        <Label className="text-right">
          {label}
          {requiredStar}
        </Label>
      )}
      <div style={style}>
        <AsyncPaginate
          value={field.value}
          debounceTimeout={150}
          loadOptions={(inputValue, loadedOptions, { page }) => {
            return getAutocompleteAPI(autocompleteUrl, inputValue, page).then(
              (data) => {
                return {
                  options: makeOptionsCall ? makeOptionsCall(data) : makeOptions(data.results),
                  hasMore: data?.pagination?.more ?? false,
                  additional: {
                    page: data.page + 1,
                  },
                }
              }
            )
          }}
          cacheUniqs={cacheUniqs}
          isDisabled={disabled}
          loadOptionsOnMenuOpen
          placeholder={placeholder}
          styles={customStyles}
          onBlur={myBlur}
          classNamePrefix={classNames({
            InvalidReactSelect: showError,
          })}
          className={className}
          onChange={(data) => {
            if (data) {
              form.setFieldValue(field.name, data)
            } else {
              form.setFieldValue(field.name, null)
            }
          }}
          isClearable={true}
          name={field.name}
          additional={{
            page: 1,
          }}
          {...props}
        />
        {showError && getIn(form.errors, field.name) && (
          <FormFeedback className="d-block">{error}</FormFeedback>
        )}
      </div>
    </FormGroup>
  )
}
